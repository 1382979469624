
/**
 * @description
 * 홈 > 재능 > 이번주 인기 급상승 아이템
 */

import { NEW_HOME_SECTION_NAME } from '@/utils/amplitudeNames.js';
import { HOME_TYPE } from '@/utils/amplitudeParams.js';
import { getPathAt } from '@/utils/path';
import { mapGetters } from 'vuex';

import TrendingThumbnail from './TrendingThumbnail.vue';

export default {
  name: 'TrendingItem',
  components: { TrendingThumbnail },
  props: {
    rank: { type: Number, default: 0 },
    id: { type: Number, default: 0 },
    thumbnailImage: { type: String, default: null },
    title: { type: String, default: null },
    teacherName: { type: String, default: null },
    profileImage: { type: String, default: null },
    productStatus: { type: String, default: null },
    classType: { type: String, default: '' },
    teacherId: { type: Number, default: 0 },
    ratingCount: { type: Number, default: 0 },
  },
  computed: {
    ...mapGetters('user/product', ['myProductList']),
    isEnrolled() {
      return this.myProductList.includes(this.id);
    },
  },
  methods: {
    logEvent() {
      this.logUserEvent('click_product', {
        section: NEW_HOME_SECTION_NAME.TRENDING,
        product_id: this.id,
        at: getPathAt(this.$route),
        class_review_cnt: this.ratingCount,
        teacher_id: this.teacherId,
        class_type: this.classType,
        product_status: this.productStatus,
        is_enrolled: this.isEnrolled,
        home_type: HOME_TYPE.TALENT,
      });
    },
  },
};
